import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Image,
  Modal,
  Stack,
  Alert,
} from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import TutorialCNPJ from "../assets/images/tutorial-cnpj.jpg";
import TutorialNumero from "../assets/images/tutorial-numero.jpg";
import TutorialSerie from "../assets/images/tutorial-serie.jpg";
import HeroImage from "../assets/images/hero-image.png";
import HeroImageShadow from "../assets/images/hero-image-shadow.png";
import HeroImageFlares from "../assets/images/hero-image-flares.png";
import { InputMask } from "@react-input/mask";
import { SubmitHandler, useForm } from "react-hook-form";
import {
  ChangeEvent,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Swal from "sweetalert2";
import useApi from "../hooks/useApi";
import {
  resizeFile,
  validatePromotionDate,
  validatePromotionValue,
} from "../utils";
import { AppContext } from "../providers/AppProvider";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export function Component() {
  const formRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const app = useContext(AppContext);
  const api = useApi();

  const [showTutorial, setShowTutorial] = useState("");

  const handleShowTutorial = (tutorial: string) => () => {
    setShowTutorial(tutorial);
  };

  const { register, handleSubmit, setValue, setError, formState } = useForm({
    defaultValues: {
      arquivo: "",
      rede: "",
      cnpj: "",
      numero_cupom_fiscal: "",
      serie_cupom_fiscal: "",
      data_compra: "",
      valor_compra: "R$ 0,00",
      cliente_meu_carrefour: "",
      usou_cartao_carrefour: "",
      arquivo_cupom_fiscal: "",
      recaptcha_token: "",
    },
  });

  const loadRecaptcha = useCallback(async () => {
    if (executeRecaptcha) {
      executeRecaptcha("coupon_register").then((token) =>
        setValue("recaptcha_token", token),
      );
    }
  }, [executeRecaptcha, setValue]);

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];

      if (file) {
        const image = await resizeFile(file);
        setValue("arquivo_cupom_fiscal", image);
      }
    } catch (err) {
      // Do nothing
    }
  };

  const handleSuccess = useCallback(
    (response: any) => {
      Swal.close();

      if (response.data?.id) {
        navigate(`/roleta/${response.data?.id}`);
      } else {
        navigate(response.redirectTo);
      }
    },
    [navigate],
  );

  const handleError = useCallback(
    (error: any) => {
      Object.entries<any>(error.data).forEach(([field, messages]) => {
        if (field === "recaptcha_token") {
          loadRecaptcha();
        }

        if (field === "arquivo_cupom_fiscal") {
          setError("arquivo", { type: "manual", message: messages[0] });
        } else {
          setError(field as any, { type: "manual", message: messages[0] });
        }
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: error.message,
      });
    },
    [loadRecaptcha, setError],
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando o seu cupom",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      data.cliente_meu_carrefour =
        data.cliente_meu_carrefour === "S" ? "S" : "N";
        
      data.usou_cartao_carrefour =
        data.usou_cartao_carrefour === "S" ? "S" : "N";

      api.registerCupom(data).then(handleSuccess).catch(handleError);
    },
    [api, handleError, handleSuccess],
  );

  useEffect(() => {
    register("recaptcha_token", {
      required:
        "O Google reCAPTCHA não foi carregado corretamente. Atualize a página e tente novamente.",
    });

    loadRecaptcha();
  }, [register, loadRecaptcha]);

  const handleParticipationLimit = useCallback(
    (response: any) => {
      if (response.data?.pode_participar === "N") {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          html:
            response.message ||
            'Você atingiu o limite de participação. <a class="text-primary" href="/regulamento">Consulte o regulamento</a> para mais informações.',
          confirmButtonText: "Ver meu extrato",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
        }).then(() => {
          navigate("/meu-extrato");
        });
      } else {
        Swal.close();
      }
    },
    [navigate],
  );

  useEffect(() => {
    Swal.fire({
      title: "Aguarde...",
      text: "Estamos carregando as informações",
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    api
      .getParticipationLimit()
      .then(handleParticipationLimit)
      .catch(handleParticipationLimit);
  }, [api, handleParticipationLimit]);

  if (!app.isAuthenticated) {
    return <Navigate to="/login" state={{ location }} replace />;
  }

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">CADASTRAR CUPOM</h1>

          <Row>
            <Col md={{ span: 8, offset: 2 }}>
              <Form
                ref={formRef}
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="off"
                autoCorrect="off"
                noValidate
              >
                <Row>
                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="rede">Rede*</Form.Label>
                    <Form.Select
                      id="rede"
                      defaultValue={""}
                      isInvalid={!!formState.errors.rede}
                      {...register("rede", {
                        required: "Este campo é obrigatório",
                      })}
                      autoFocus
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      <option value="Carrefour">Carrefour</option>
                      <option value="Carrefour Bairro">Carrefour Bairro</option>
                      <option value="Nacional">Nacional</option>
                      <option value="Bom Preço">Bom Preço</option>
                    </Form.Select>
                    <Form.Text>
                      Selecione a rede onde você efetuou a compra
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.rede?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="cnpj">
                      CNPJ da Loja*
                      <Button
                        tabIndex={-1}
                        className="form-info"
                        type="button"
                        onClick={handleShowTutorial(TutorialCNPJ)}
                      >
                        <i className="bi bi-info-circle-fill"></i>
                      </Button>
                    </Form.Label>
                    <Form.Control
                      as={InputMask}
                      mask="__.___.___/____-__"
                      placeholder="__.___.___/____-__"
                      replacement={{ _: /\d/ }}
                      id="cnpj"
                      isInvalid={!!formState.errors.cnpj}
                      {...register("cnpj", {
                        required: "Este campo é obrigatório",
                      })}
                    />

                    <Form.Control.Feedback type="invalid">
                      {formState.errors.cnpj?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="numero_cupom_fiscal">
                      Número do cupom fiscal*
                      <Button
                        tabIndex={-1}
                        className="form-info"
                        type="button"
                        onClick={handleShowTutorial(TutorialNumero)}
                      >
                        <i className="bi bi-info-circle-fill"></i>
                      </Button>
                    </Form.Label>
                    <Form.Control
                      id="numero_cupom_fiscal"
                      isInvalid={!!formState.errors.numero_cupom_fiscal}
                      {...register("numero_cupom_fiscal", {
                        required: "Este campo é obrigatório",
                        onChange: (event) => {
                          setValue(
                            "numero_cupom_fiscal",
                            event.target.value.replace(/\D/g, ""),
                          );
                        },
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.numero_cupom_fiscal?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="serie_cupom_fiscal">
                      Série do cupom fiscal*
                      <Button
                        tabIndex={-1}
                        className="form-info"
                        type="button"
                        onClick={handleShowTutorial(TutorialSerie)}
                      >
                        <i className="bi bi-info-circle-fill"></i>
                      </Button>
                    </Form.Label>
                    <Form.Control
                      id="serie_cupom_fiscal"
                      isInvalid={!!formState.errors.serie_cupom_fiscal}
                      {...register("serie_cupom_fiscal", {
                        required: "Este campo é obrigatório",
                        onChange: (event) => {
                          setValue(
                            "serie_cupom_fiscal",
                            event.target.value.replace(/\D/g, ""),
                          );
                        },
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.serie_cupom_fiscal?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="data_compra">
                      Data da compra*
                    </Form.Label>
                    <Form.Control
                      as={InputMask}
                      mask="__/__/____"
                      placeholder="__/__/____"
                      replacement={{ _: /\d/ }}
                      id="data_compra"
                      isInvalid={!!formState.errors.data_compra}
                      {...register("data_compra", {
                        required: "Este campo é obrigatório",
                        validate: validatePromotionDate(
                          import.meta.env.VITE_PARTICIPATION_START_DATE,
                          import.meta.env.VITE_PARTICIPATION_END_DATE,
                          "Data de compra fora do período de participação",
                        ),
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.data_compra?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="valor_compra">
                      R$ Valor da compra*
                    </Form.Label>
                    <Form.Control
                      id="valor_compra"
                      isInvalid={!!formState.errors.valor_compra}
                      onClick={(event: any) => {
                        event.target.setSelectionRange(
                          event.target.value.length,
                          event.target.value.length,
                        );
                      }}
                      {...register("valor_compra", {
                        required: "Este campo é obrigatório",
                        onChange: (event) => {
                          event.target.setSelectionRange(
                            event.target.value.length,
                            event.target.value.length,
                          );

                          let value = event.target.value
                            .replace(/\D/g, "")
                            .replace(/^0+/, "");

                          value = value
                            .padStart(3, "0")
                            .replace(/(\d{2})$/, ",$1")
                            .replace(/(?=(\d{3})+(\D))\B/g, ".");

                          setValue("valor_compra", `R$ ${value}`);
                        },
                        validate: validatePromotionValue(
                          150,
                          "O valor da compra é menor que R$ 150,00",
                        ),
                      })}
                    />
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.valor_compra?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="numero_cupom">
                      Compra efetuada com cartão Carrefour?*
                    </Form.Label>
                    <Form.Select
                      id="usou_cartao_carrefour"
                      isInvalid={!!formState.errors.usou_cartao_carrefour}
                      {...register("usou_cartao_carrefour", {
                        required: "Este campo é obrigatório",
                      })}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      <option value="S">Sim</option>
                      <option value="N">Não</option>
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      {formState.errors.rede?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} md={6} className="mb-3">
                    <Form.Label htmlFor="numero_cupom">
                      Cliente Meu Carrefour?*
                    </Form.Label>
                    <Form.Select
                      id="cliente_meu_carrefour"
                      isInvalid={!!formState.errors.cliente_meu_carrefour}
                      {...register("cliente_meu_carrefour", {
                        required: "Este campo é obrigatório",
                      })}
                    >
                      <option value="" disabled>
                        Selecione uma opção
                      </option>
                      <option value="S">Sim</option>
                      <option value="N">Não</option>
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">
                      {formState.errors.cliente_meu_carrefour?.message}
                    </Form.Control.Feedback>
                  </Col>

                  <Col xs={12} className="mb-3">
                    <Form.Label>Selecione o arquivo*</Form.Label>
                    <Form.Control
                      type="file"
                      id="arquivo"
                      isInvalid={!!formState.errors.arquivo}
                      accept="image/png, image/gif, image/jpeg"
                      {...register("arquivo", {
                        required: "Este campo é obrigatório",
                        onChange: handleUpload,
                      })}
                    />
                    <Form.Text>
                      Tire uma foto do seu cupom fiscal - Formato .jpg ou .png
                      até 6MB
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {formState.errors.arquivo?.message}
                    </Form.Control.Feedback>
                  </Col>
                </Row>

                {formState.errors.recaptcha_token && (
                  <Alert variant="danger" className="mb-3">
                    {formState.errors.recaptcha_token.message}
                  </Alert>
                )}

                <div className="d-flex my-5">
                  <Button
                    type="submit"
                    variant="primary"
                    className="button mx-auto"
                  >
                    CADASTRAR CUPOM
                  </Button>
                </div>
              </Form>
            </Col>
          </Row>

          <div className="register-footer-image">
            <div className="hero__image">
              <Image
                className="hero__left__logo"
                src={HeroImage}
                alt=""
                fluid
              />
              <Image
                className="hero__left__shadow"
                src={HeroImageShadow}
                alt=""
                fluid
              />

              <Image
                className="hero__left__flares"
                src={HeroImageFlares}
                alt=""
                fluid
              />
            </div>
          </div>
        </Container>
      </section>
      <Modal show={!!showTutorial}>
        <Modal.Body>
          <Stack className="align-items-center">
            <Image className="mb-4" src={showTutorial} alt="" width={300} />

            <Button className="button" onClick={handleShowTutorial("")}>
              OK
            </Button>
          </Stack>
        </Modal.Body>
      </Modal>
    </Suspense>
  );
}
